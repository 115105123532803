<template>
  <div class="cdp-selector">
    <Autocomplete
      class="w-100"
      :label="$t('menu.cdp')"
      :api="{
        url: 'cdp',
        query: 'name',
        full: true,
        params: filterParams
      }"
      v-model="cdpId"
      :model="model"
      :setFirst="setFirst"
      :tabindex="tabindex"
      :field="field"
      sortField="id"
      :required="required"
      @selectRaw="CDP = $event || {}"
      @ready="CDP = $event.selected || {}"
    >
      <template #preInput>
        <ColorIdentifier v-show="cdpColor" class="CDP-mainColor" :color="cdpColor" />
      </template>
      <template #results="{ data: CDP }">
        <div class="cdp-results">
          <ColorIdentifier :color="CDP.color" />
          <div>
            <strong>{{ CDP.name }}</strong>
            <br />
            <small>{{ CDP.full_address }}</small>
          </div>
        </div>
      </template>
    </Autocomplete>
  </div>
</template>

<script>
import Autocomplete from '../Autocomplete.vue';
import ColorIdentifier from './ColorIdentifier.vue';
export default {
  components: {
    Autocomplete,
    ColorIdentifier
  },
  data() {
    return {
      CDP: {},
      cdpId: this.value
    };
  },
  computed: {
    cdpFilterParam() {
      const { is_cdp, cdp_id } = this.$store.getters.AUTH;
      const allowFilter = is_cdp && cdp_id;
      return allowFilter ? { id: 'id', value: cdp_id } : null;
    },
    filterParams() {
      const params = [{ id: 'is_active', value: 1 }];
      if (this.cdpFilterParam) params.push(this.cdpFilterParam);
      return params;
    },
    cdpColor() {
      return this.CDP.color || this.baseColor;
    }
  },
  watch: {
    value(val) {
      this.cdpId = val;
    },
    cdpId() {
      this.$emit('input', this.cdpId);
    }
  },
  props: {
    baseColor: { type: String, default: null },
    field: { type: String, default: 'name' },
    model: { type: String, default: 'id' },
    required: { type: Boolean, default: false },
    setFirst: { type: Boolean, default: false },
    tabindex: { type: Number, default: 0 },
    value: { type: [Number, String], default: null }
  }
};
</script>

<style lang="sass" scoped>
.cdp-selector
  width: 100%
  .CDP-mainColor
    height: 40px
  .cdp-results
    display: grid
    grid-template-columns: 20px 1fr
  .color-identifier
    height: 100%
</style>
