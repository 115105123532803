var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cdp-selector"},[_c('Autocomplete',{staticClass:"w-100",attrs:{"label":_vm.$t('menu.cdp'),"api":{
      url: 'cdp',
      query: 'name',
      full: true,
      params: _vm.filterParams
    },"model":_vm.model,"setFirst":_vm.setFirst,"tabindex":_vm.tabindex,"field":_vm.field,"sortField":"id","required":_vm.required},on:{"selectRaw":function($event){_vm.CDP = $event || {}},"ready":function($event){_vm.CDP = $event.selected || {}}},scopedSlots:_vm._u([{key:"preInput",fn:function(){return [_c('ColorIdentifier',{directives:[{name:"show",rawName:"v-show",value:(_vm.cdpColor),expression:"cdpColor"}],staticClass:"CDP-mainColor",attrs:{"color":_vm.cdpColor}})]},proxy:true},{key:"results",fn:function(ref){
    var CDP = ref.data;
return [_c('div',{staticClass:"cdp-results"},[_c('ColorIdentifier',{attrs:{"color":CDP.color}}),_c('div',[_c('strong',[_vm._v(_vm._s(CDP.name))]),_c('br'),_c('small',[_vm._v(_vm._s(CDP.full_address))])])],1)]}}]),model:{value:(_vm.cdpId),callback:function ($$v) {_vm.cdpId=$$v},expression:"cdpId"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }